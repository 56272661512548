<template>
  <div class="page flex_column">
    <div class="item flex_column" :class="item.color" v-for="item in dormitoryArea" :key="item.id">
      <div class="title">{{ item.title }}</div>
      <div class="flex_align content">
        <img class="img" src="../../pic/info.png" alt=""><span class="info">{{ item.info }}</span>
      </div>
      <div class="flex_align content">
        <img class="img" src="../../pic/price.png" alt=""><span class="info">{{ item.price }}</span>
      </div>
      <div class="flex_align content">
        <img class="img" src="../../pic/location.png" alt=""><span class="info">{{ item.address }}</span>
      </div>
      <div class="flex_justify_end flex_1 flex_align">
        <button class="dimensional_button flex_center" :class="item.buttonColor" @click="go(item)">选择</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getApartment } from '@/api/dormitory'

export default {
  name: 'chooseDormitoryArea',
  data () {
    return {
      dormitoryArea: [
        { id: 1, title: '金山公寓', info: '共有硕士4人间、博士2人间', price: '2931元 / 学年', address: '温州市鹿城区鹿城路169号', color: 'orange', buttonColor: 'dimensional_button_purple' },
        { id: 2, title: '教育宾馆', info: '共有硕士4人间、博士2人间', price: '2931元 / 学年', address: '浙江省温州市鹿城区蝉街32号', color: 'green', buttonColor: 'dimensional_button_green' }
      ],
      clazzId: 0,
      sex: -1,
      edu: -1,
      userInfo: JSON.parse(window.localStorage.getItem('userInfo'))
    }
  },
  activated () {
    const d = {
      clazzId: this.userInfo.clazzId,
      edu: this.userInfo.edu,
      sex: this.userInfo.sex
    }
    const dataMap = ['clazzId', 'sex', 'edu']
    let refresh = false
    for (let i = 0; i < dataMap.length; i++) {
      const item = dataMap[i]
      if (d[item] !== this[item]) {
        this[item] = d[item]
        refresh = true
      }
    }
    if (refresh) {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.dormitoryArea = []
      getApartment({ clazzId: this.clazzId, sex: this.sex, edu: this.userInfo.edu }).then(res => {
        const { data: r } = res
        const { code, msg, data } = r
        if (code === 0) {
          const color = [
            ['orange', 'dimensional_button_purple'],
            ['green', 'dimensional_button_green']
          ]
          this.dormitoryArea = data.map((item, index) => {
            const { id, name: title, type: info, cost: price, address } = item
            const colorA = color[index % 2]
            return { id, title, info, price, address, color: colorA[0], buttonColor: colorA[1] }
          })
        } else {
          this.$toast.fail(msg)
        }
      })
    },
    go (item) {
      this.$router.push({ path: '/dormitory/roomChoose', query: { area: item.id } })
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/button";
.page {
  background: linear-gradient(180deg, rgba(63, 110, 254, 0.8) 0%, #FFFFFF 100%);
  align-items: center;
  .item {
    width: 305px; height: 181px;
    padding: 20px;
    background: #FFF;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 0 8px rgba(63, 110, 254, .1);
    position: relative;
    &:first-child {
      margin-top: 30px;
    }
    &:before {
      content: '';
      width: 80px; height: 8px;
      border-radius: 50px;
      position: absolute;
      top: -4px; left: 28px;
    }
    &.orange:before {
      background: #FEA330;
    }
    &.green:before {
      background: #1CB394;
    }
    .title {
      font-weight: bold;
      font-size: 19px;
      color: #0A0A0A;
      margin-bottom: 20px;
    }
    .content {
      margin-bottom: 10px;
    }
    .img {
      width: 15px; height: 17px;
      margin-right: 13px;
    }
    .info {
      color: #ACC2D8;
      font-size: 14px;
    }
  }
}
</style>
